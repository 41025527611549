<template>
  <vs-dropdown
    vs-trigger-click
    class="cursor-pointer mr-4 items-per-page-handler"
  >
    <div
      class="
        p-4
        border border-solid
        d-theme-border-grey-light
        rounded-full
        d-theme-dark-bg
        cursor-pointer
        flex
        items-center
        justify-between
        font-medium
      "
    >
      <span class="mr-2">
        {{ page * perPage - (perPage - 1) }} -
        {{ total - page * perPage > 0 ? page * perPage : total }}
        of {{ queriedItems }}
      </span>
      <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
    </div>
    <vs-dropdown-menu>
      <vs-dropdown-item @click="handleChange(5)">
        <span>5</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="handleChange(10)">
        <span>10</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="handleChange(15)">
        <span>15</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="handleChange(20)">
        <span>20</span>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
const VALIDATION = {
  type: Number,
  required: true,
};

export default {
  props: {
    page: VALIDATION,
    perPage: VALIDATION,
    total: VALIDATION,
    queriedItems: VALIDATION,
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>
