import axios from '@/axios';
import { BrandAction } from '@/store/actionTypes';
import { BrandMutation } from '@/store/mutationTypes';

export default {
  [BrandAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/brands', item)
        .then(({ data }) => {
          commit(BrandMutation.ADD, Object.assign(item, { id: data.id }));
          resolve(data);
        })
        .catch(reject);
    });
  },
  [BrandAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/brands')
        .then(({ data }) => {
          commit(BrandMutation.SET, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [BrandAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/brands/${item.id}`, item)
        .then(({ data }) => {
          commit(BrandMutation.UPDATE, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [BrandAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/brands/${id}`)
        .then(({ data }) => {
          commit(BrandMutation.REMOVE, id);
          resolve(data);
        })
        .catch(reject);
    });
  },
};
