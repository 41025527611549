<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} BRAND</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          v-validate="'required'"
          class="w-full"
          name="name"
        />
        <v-error :err="errors" field="name" />
      </div>

      <!-- DESCRIPTION -->
      <div class="mt-4">
        <vs-label text="Description" />
        <vs-textarea v-model="dataDescription" name="description" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';

import { BrandAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    vError,
  },
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataDescription: '',
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.dataName;
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, name, description } = this.data;

        this.dataId = id;
        this.dataName = name;
        this.dataDescription = description;

        this.initValues();
      }
    },
  },
  methods: {
    ...mapActions('brand', [BrandAction.add, BrandAction.update]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataName = '';
      this.dataDescription = '';
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = {
        id: this.dataId,
        name: this.dataName,
        description: this.dataDescription,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateBrand(obj);

          this.notifySuccess(
            'Brand updated',
            'The brand has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating brands.');
        }
      } else {
        delete obj.id;

        try {
          await this.addBrand(obj);

          this.notifySuccess(
            'Brand added',
            'The brand has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding brands.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
  },
};
</script>
