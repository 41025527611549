export default {
  methods: {
    confirmDelete(id, name, cbName = 'deleteData') {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this[cbName](id),
        acceptText: 'Delete',
      });
    },
  },
};
